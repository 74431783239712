import './bootstrap';
$(window).on('turbolinks:load', function () {
    if ($(window).width() > 992) {
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 40) {
                $('#navbar_top').addClass("fixed-top");
                // add padding top to show content behind navbar
                $('body').css('padding-top', $('.navbar').outerHeight() + 'px');
            } else {
                $('#navbar_top').removeClass("fixed-top");
                // remove padding top from body
                $('body').css('padding-top', '0');
            }
        });
    }


    let pencarian = $('#pencarian')
    pencarian.on('submit', function (e) {
        e.preventDefault()
        let query = pencarian.find('input').val()
        Turbolinks.visit(__sharedData.pages['berita.cari'] + query)
    })
    pencarian.find('button').on('click', function () {
        let query = pencarian.find('input').val()
        Turbolinks.visit(__sharedData.pages['berita.cari'] + query)
    })
})
